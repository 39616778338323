<template>
  <div class="container">
    

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList"
    >
      <div class="search-box">
        <input type="text" name="search" class="search-inpt" v-model="keywords" @change="getList" placeholder="请输入关键字搜索">
        <img src="@/assets/icon_search.png" @click="getList" alt="" class="search-icon">
      </div>

      <van-cell-group>
        <van-cell :title="item.estateName" v-for="item in list" :key="item.estateId" @click="changeAct(item)">
          <template #right-icon>
            <van-icon name="checked" class="right-icon act" v-if="item.estateId == actId" />
            <van-icon name="circle" class="right-icon" v-else />
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    
    <!-- <van-button type="info" block @click="goBind">绑定房产</van-button> -->
  </div>
</template>

<script>  

import { getEstateList } from '@/api/houseApi.js'
export default {
  name: 'memberVerifyList',
  components: {
  }, 
  data() {
    return {
      keywords: '',
      list: [],
      loading: false,
      finished: false,
      actId: '',
    }
  },
  // 计算属性
  computed: { 
  },

  created() {
    // this.actId = this.$route.query.estateId
    this.actId = this.$store.state.actEstate.estateId
    
    // this.actId = this.$store.getters.currentEstate.deptId
  },  
  methods: {
    
    changeAct(row) {
      this.$store.commit('SET_ACT_ESTATE', row)
      this.$router.go(-1)
    },
    // goBind() {
    //   this.$router.push('/houseRegister')
    // },
    getList() {
      getEstateList({
        name: this.keywords
      }).then(res => {
        if(res.code == 200) {
          this.loading = false
          this.finished = true; 
          this.list = res.data
        }
      })
      
    },
  }
}
</script>


<style lang='scss' scoped>
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right-icon {
  font-size: 14px;
  line-height: inherit;
  color: #969799;
}
.right-icon.act {
  color: #1989fa;
} 
.search-box{
  background: #F2F2F2;
  display: flex;
  align-items: center;
  padding: 0 12px;
  .search-inpt{
    flex: 1;
    font-size: 13px;
    background:none;  
    outline:none;  
    border:none;
    height: 36px;
  }
  .search-inpt::placeholder{
    color: #999;
  }
  .search-icon{
    width: 18px;
  }
}
</style>
